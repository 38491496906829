.root-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-box-container {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.middle-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.name-text {
  font-family: "SF Pro", sans-serif;
  font-size: 4vw;
  color: #42423e;
  margin-top: -1rem;
}

.subtitle-text {
  font-family: "SF Pro", sans-serif;
  font-size: 2vw;
  font-weight: 500;
  color: #42423e;
}

.icon-row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2vw;
  margin-left: -2vw;
  gap: 0.2vw;
}

.head-shot {
  width: 54%;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 1;
  z-index: 1000;
  /* Make sure it sits on top of other content */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
  opacity: 0;
}

/* Horizontal divider style */
.divider {
  max-width: 800px;
  /* Adjust width as needed */
  margin: 3rem auto;
  /* Add space around the line */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust color and thickness */
  opacity: 0.7;
  /* Optional: Makes it less prominent */
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  .divider {
    margin: 3rem 1rem;
    /* Add left and right space on mobile */
  }
}

@media (max-width: 480px) {
  .divider {
    margin: 3rem 2rem;
    /* Add even more left and right space on smaller screens */
  }
}

.copyright {
  font-family: "SF Pro", sans-serif;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #666;
}